// Handles fetching and sending of alert data from MycroHarvest API

import axios from 'axios';

export async function fetchData(token, baseURL, AccountID) {
    // input: baseURL (dev or prod)
    // output: JSON object containing raw data
    try {
        const response = await axios.get(baseURL + 'account/' + AccountID + '/alert', {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch Alert Data from MycroHarvest API. Error: ", error);
        throw error;
    }
}

export async function updateAlert(token, baseURL, AccountID, AlertID, AlertData) {
    // input payload: JSON object containing alert data (capability, type, comparator, threshold_value)
    try {
        const response = await axios.put(baseURL + 'account/' + AccountID + '/alert/' + AlertID, JSON.stringify(AlertData), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });
        return response.data;
    } catch (error) {
        console.error("Failed to PUT Alert Data to MycroHarvest API. Error: ", error);
        throw error;
    }
}

export async function newAlert(token, baseURL, AccountID, AlertData) {
    // input payload: JSON object containing alert data (capability, type, comparator, threshold_value)
    try {
        const response = await axios.post(baseURL + 'account/' + AccountID + '/alert', JSON.stringify(AlertData), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });
        return response.data;
    } catch (error) {
        console.error("Failed to POST Alert Data to MycroHarvest API. Error: ", error);
        throw error;
    }
}