export function processDeviceData(deviceData) {
    // input: JSON object containing raw data
    // output: list of flat JSON objects

    const processedData = [];
    Object.values(deviceData).forEach(device => {
        const name = device.name;
        const ip = device.ip;
        const status = device.status;
        processedData.push({ name: name, status: status });
    });
    return processedData;
}

export function processAlertData(alertData) {
    // input: JSON object containing raw data
    // output: list of flat JSON objects

    const processedData = [];
    // we need to keep track of the ID with the data, so we need to make the alertData available inside
    // the forEach loop
    // iterate through the data
    Object.entries(alertData).forEach(([uuid, alert]) => {
        const capability = alert.capability;
        const type = alert.type;
        const measured_value = alert.measured_value;
        const comparator = alert.comparator;
        const threshold_value = alert.threshold_value;
        var status;
        if (alert.status === "not triggered") {
            status = "✅";
        }
        else {
            status = "❌";
        }
        processedData.push({ id: uuid, capability: capability, type: type, measured_value: measured_value, comparator: comparator, value: threshold_value, status: status });
    });
    return processedData;
}