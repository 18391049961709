// Handles conversion of raw data into a graph-readable format

export function convertToTimestamp(datetimeStr) {
    // split date and time
    const datePart = datetimeStr.split('_')[0];
    const timePart = datetimeStr.split('_')[1];

    // split date into year, month, day
    const year = datePart.slice(0, 4);
    const month = datePart.slice(4, 6);
    const day = datePart.slice(6, 8);
    const hour = timePart.slice(0, 2);
    const minute = timePart.slice(2, 4);
    const second = timePart.slice(4, 6);

    // build a string
    const dateString = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

    // create a new unix epoch time
    return new Date(dateString).getTime();
}

export function convertToTimestampNew(datetimeStr) {
    // split date and time
    const datePart = datetimeStr.split('T')[0];
    const timePart = datetimeStr.split('T')[1];

    // split date into year, month, day
    const year = datePart.slice(0, 4);
    const month = datePart.slice(5, 7);
    const day = datePart.slice(8, 10);
    const hour = timePart.slice(0, 2);
    const minute = timePart.slice(3, 5);
    const second = timePart.slice(6, 8);

    // build a string
    const dateString = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

    // create a new unix epoch time
    return new Date(dateString).getTime();
}