import React from 'react';
import { AlertTable } from '../Tables/Table';
import "./Alerts.css"

export const Alerts = ({ token, AlertState, selectedSection, baseURL, AccountID, CaptureStateChange, setCaptureStateChange }) => {

    const [editRowId, setEditRowId] = React.useState(null);

    const handleEditClick = (rowId) => {
        setEditRowId(rowId);
    };

    var NewAlert = [{
        id: null,
        capability: null,
        type: null,
        measured_value: null,
        comparator: null,
        value: null,
        status: null
    }]

    const columns = React.useMemo(
        () => [
        //   { Header: 'Alert ID', accessor: 'id' }, // Column ID is required
          { Header: 'Capability', accessor: 'capability' }, // Define how each column is rendered
          { Header: 'Type', accessor: 'type' },
          { Header: 'Current', accessor: 'measured_value' },
          { Header: 'is...', accessor: 'comparator' },
          { Header: 'Threshold', accessor: 'value' },
          { Header: 'Status', accessor: 'status' },
        ],
        [] // Dependencies array, empty means it only computes once
      );

    return(
        <div class={`ModernDashbordMain-MasterGraphContainer ${!(selectedSection === 4) && "hidden"}`}>
            <div class="ModernDashbordMain-MasterGraphHeaderContainer">
                <h1 class="ModernDashbordMain-PhotosContainerText">Alert Settings</h1>
            </div>

            {/* insert table */}

            { AlertState ? (
                <>
                    <AlertTable token columns={columns} data={AlertState} baseURL={baseURL} AccountID={AccountID} />
                    <h1 class="ModernDashbordMain-PhotosContainerText">Configure a New Alert</h1>
                    <AlertTable token columns={columns} data={NewAlert} baseURL={baseURL} AccountID={AccountID} Type="always_add"/>
                </>
            ) : (
                <h1 className="TextName">Loading...</h1>
            )}
        </div>
    );
}