import React, { useState } from 'react';
import { Container } from '@mui/material';
import "./Sidebar.css";

// Open close icon
import IconButton from '@mui/material/IconButton';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ViewListIcon from '@mui/icons-material/ViewList';

import { AiFillCarryOut } from 'react-icons/ai';
import { FaPhotoFilm } from 'react-icons/fa6';
import { BsGraphUp } from 'react-icons/bs';
import { AiOutlineControl } from 'react-icons/ai';
import { AiOutlineWarning } from 'react-icons/ai';
import { IoMdSettings } from 'react-icons/io';
import { TiMessages } from 'react-icons/ti';

// Account admin
import { GoSignOut } from 'react-icons/go';
import { useAuthorizer } from '@authorizerdev/authorizer-react';

export const Sidebar = ({ AccountImage, HumanUser, CompanyName, handleSectionClick, selectedSection, toggleDrawer}) => {

    return (
        <Container class="SidebarContainer">
            <div class="SidebarInfoContainer">
                <h1 class="SidebarInfoLogoText"> Mycro Harvest
                    <IconButton sx={{ color: "white" }} onClick={toggleDrawer}>
                        <ViewListIcon />
                        <ArrowLeftIcon />
                    </IconButton>
                </h1>
                <div class="SidebarProfileContainer">
                    <div class="SidebarProfilePictureContainer">
                        {AccountImage ? (
                            <img src={AccountImage} class="SidebarProfilePicture"></img>
                        ) : (
                            <img src="https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png" class="SidebarProfilePicture"></img>
                        )}
                    </div>
                    <div class="SidebarProfileTextContainer">
                        {HumanUser ? (
                            <h1 class="SidebarProfileText">{HumanUser}</h1>
                        ) : (
                            <h1 class="SidebarProfileText">Loading...</h1>
                        )}
                        {CompanyName ? (
                            <h1 class="SidebarProfileSubText">{CompanyName}</h1>
                        ) : (
                            <h1 class="SidebarProfileSubText">Loading...</h1>
                        )}

                    </div>
                </div>
                <div class="SidebarInfoButtonsContainer">
                    <button onClick={() => handleSectionClick(0)} class={`SidebarInfoTextButton ${(selectedSection === 0) && 'selected'}`}>
                        <h2 class={`SidebarInfoText ${(selectedSection === 0) && "selected"}`}> <AiFillCarryOut /> &nbsp; Analytics </h2>
                    </button>
                    <button onClick={() => handleSectionClick(1)} class={`SidebarInfoTextButton ${(selectedSection === 1) && 'selected'}`}>
                        <h2 class={`SidebarInfoText ${(selectedSection === 1) && "selected"}`} onClick={() => handleSectionClick(1)}> <FaPhotoFilm /> &nbsp; Photos </h2>
                    </button>
                    <button onClick={() => handleSectionClick(2)} class={`SidebarInfoTextButton ${(selectedSection === 2) && 'selected'}`}>
                        <h2 class={`SidebarInfoText ${(selectedSection === 2) && "selected"}`} onClick={() => handleSectionClick(1)}> <BsGraphUp /> &nbsp; Master Graph </h2>
                    </button>
                    <button onClick={() => handleSectionClick(3)} class={`SidebarInfoTextButton ${(selectedSection === 3) && 'selected'}`}>
                        <h2 class={`SidebarInfoText ${(selectedSection === 3) && "selected"}`} onClick={() => handleSectionClick(1)}> <AiOutlineControl /> &nbsp; Devices </h2>
                    </button>
                    <button onClick={() => handleSectionClick(4)} class={`SidebarInfoTextButton ${(selectedSection === 4) && 'selected'}`}>
                        <h2 class={`SidebarInfoText ${(selectedSection === 4) && "selected"}`} onClick={() => handleSectionClick(1)}> <AiOutlineWarning /> &nbsp; Alerts </h2>
                    </button>
                </div>
                <div class="SidebarBottomContainer">
                    <hr class="SidebarBottomLine"></hr>
                    <div class="SidebarInfoButtonsContainer">
                        <h2 class="SidebarInfoTextButton"> <IoMdSettings/> &nbsp; Settings </h2>
                        <h2 class="SidebarInfoTextButton"> <TiMessages/> &nbsp; Contact Us </h2>
                        <h2 class="SidebarInfoTextButton" onClick={useAuthorizer().logout}> <GoSignOut/> &nbsp; Logout </h2>
                    </div>
                </div>
            </div>
        </Container>
    );
};