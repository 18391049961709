import React from 'react'; // Import React
import { useTable } from 'react-table'
import "./Tables.css"

// Update components
import { updateAlert, newAlert } from '../../Data/AlertData';
import { processAlertData } from '../../Data/TableProcess';

// This is a functional component.
export function DeviceTable({ columns, data }) {
    // useMemo is correctly used within the component.
    // This ensures columns and data are memoized and only recalculated if dependencies change.
    
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({ columns, data });

    // Here we return the table JSX
    return (
        <table {...getTableProps()} className="Table">
        <thead>
            {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} className='UpperTable'>
                    {column.render('Header')}
                </th>
                ))}
            </tr>
            ))}
        </thead>
        <tbody {...getTableBodyProps()}>
            {rows.map(row => {
            prepareRow(row);
            return (
                <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                    return (
                    <td {...cell.getCellProps()} className='Cell'>
                        {cell.render('Cell')}
                    </td>
                    );
                })}
                </tr>
            );
            })}
        </tbody>
        </table>
    );
}  

export function AlertTable({ token, columns, data, baseURL, AccountID, Type}) {
    // useMemo is correctly used within the component.
    // This ensures columns and data are memoized and only recalculated if dependencies change.
    
    const [tableData, setTableData] = React.useState(data);

    const [editRowId, setEditRowId] = React.useState(null);
    const [edits, setEdits] = React.useState({});
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({ columns, data: tableData });

    const nonEditableColumns = ['status', 'measured_value']; // Example non-editable columns

    const handleInputChange = (rowId, columnId, value) => {
        const newEdits = { ...edits, [rowId]: { ...edits[rowId], [columnId]: value } };
        setEdits(newEdits);
    };

    const saveEdits = async (rowId) => {
        if (!edits[rowId]) {
            // no changes to save
            setEditRowId(null);
            return;
        }
        // routine for not Type === "always_add" # DEFAULT FOR BIG TABLE
        if (Type !== "always_add") {
            const AlertID = data[rowId].id;
            const AlertData = edits[rowId];
            try {
                var updatedRecord = await updateAlert(token, baseURL, AccountID, AlertID, AlertData);
                // process the data
                updatedRecord = processAlertData(updatedRecord)[0];
                // update the data in the table
                const updatedData = [...tableData];
                updatedData[rowId] = { ...updatedData[rowId], ...updatedRecord };
                setTableData(updatedData);
                setEditRowId(null);
                // clear the edits
                setEdits({});
            } catch (error) {
                console.error("Failed to save edits. Error: ", error);
            }
        }
        // routine for Type === "always_add" # FOR ADD TABLE
        else {
            const AlertData = edits[rowId];
            // add default values if not present
            AlertData.capability = AlertData.capability || "temperature";
            AlertData.type = AlertData.type || "threshold";
            AlertData.comparator = AlertData.comparator || ">";
            try {
                var newRecord = await newAlert(token, baseURL, AccountID, AlertData);
                // process the data
                newRecord = processAlertData(newRecord)[0];
                // clear the edits
                setEditRowId(null);
                setEdits({});
            } catch (error) {
                console.error("Failed to save edits. Error: ", error);
            }
        }
    };

    const removeEdits = (rowId) => {
        // Simply remove the edits for the specified rowId
        const newEdits = { ...edits };
        delete newEdits[rowId];
        setEdits(newEdits);
        setEditRowId(null); // Exit edit mode
    };

    // Input configuration based on column id
    const getInputType = (columnId, rowId, value) => {
        if (nonEditableColumns.includes(columnId)) {
            return value; // Return the value as static text for non-editable columns
        }

        switch (columnId) {
            case 'status':
                return <select className='ForumSelection'
                    defaultValue={value} 
                    onChange={e => handleInputChange(rowId, columnId, e.target.value)}
                >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </select>;
            case 'capability':
                return <select className='ForumSelection'
                    defaultValue={value} 
                    onChange={e => handleInputChange(rowId, columnId, e.target.value)}
                >
                    <option value="temperature">Temperature</option>
                    <option value="carbon_dioxide">Carbon Dioxide</option>
                    <option value="humidity">Humidity</option>
                </select>;
            case 'type':
                return <select className='ForumSelection'
                    defaultValue={value} 
                    onChange={e => handleInputChange(rowId, columnId, e.target.value)}
                >
                    <option value="threshold">Threshold</option>
                    <option value="rate">Rate</option>
                    <option value="interval">Interval</option>
                </select>;
            case 'comparator':
                return <select className='ForumSelection'
                    defaultValue={value} 
                    onChange={e => handleInputChange(rowId, columnId, e.target.value)}
                >
                    <option value="&gt;">&gt;</option>
                    <option value="&gt;=">&gt;=</option>
                    <option value="=">=</option>
                    <option value="!=">!=</option>
                    <option value="&lt;=">&lt;=</option>
                    <option value="&lt;">&lt;</option>
                </select>;
            default:
                return <input type="text" defaultValue={value} onChange={e => handleInputChange(rowId, columnId, e.target.value)} />;
        }
    };

    // Function to toggle edit mode
    const toggleEdit = (rowId) => {
        setEditRowId(editRowId === rowId ? null : rowId);
    };

    // Here we return the table JSX
    return (
        <table className='Table'>
        <thead>
            {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} className='UpperTable'>
                    {column.render('Header')}
                </th>
                ))}
                <th className='UpperTable'>
                    Edit
                </th>
            </tr>
            ))}
        </thead>
        <tbody {...getTableBodyProps()}>
            {rows.map(row => {
            prepareRow(row);
            return (
                <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                    return (
                    <td {...cell.getCellProps()} className='Cell'>
                        {editRowId === row.id || Type === "always_add" ? (
                            /* Render custom input field for editing */
                            // <input type="text" defaultValue={cell.value} />
                            getInputType(cell.column.id, cell.row.id, cell.value) // Dynamic input based on the column
                        ) : (
                            /* Render static text */
                            cell.render('Cell')
                        )}
                    </td>
                    );
                })}
                    <td className='Cell'>
                        {editRowId === row.id || Type === "always_add" ? (
                            <>
                                <button className="CellButton" onClick={() => saveEdits(row.id)}>Save</button>
                                <button className="CellButton" style={{ marginLeft: '10px', paddingLeft: '2px' }} onClick={() => removeEdits(row.id)}>Remove</button>
                            </>
                        ) : ( 
                            <button className="CellButton" onClick={() => toggleEdit(row.id)}>Edit</button>
                        )}
                    </td>
                </tr>
            );
            })}
        </tbody>
        </table>
    );
}  