import React, { useState } from 'react';
import { DetailedGraph as DetailedGraphComponent } from '../Graphs/DetailedGraph';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import './MasterGraph.css';

export const MasterGraph = ({ GraphsData, selectedSection, selectedTimeframe, timeframe }) => {
    const [showTemperature, setShowTemperature] = useState(true);
    const [showHumidity, setShowHumidity] = useState(true);
    const [showCO2_Density, setShowCO2_Density] = useState(true);
    const [masterGraphMaximumValue, setMasterGraphMaximumValue] = useState(4000);

    const handleShowTemperatureClick = () => {
        setShowTemperature(!showTemperature);
    };

    const handleShowHumidityClick = () => {
        setShowHumidity(!showHumidity);
    };

    const handleShowCO2_DensityClick = () => {
        setShowCO2_Density(!showCO2_Density);
    };

    let data = [];

    if (GraphsData !== null) {
        data = GraphsData.data;
    }

    return (
        <div className={`ModernDashboardMain-MasterGraphContainer ${!(selectedSection === 2) && "hidden"}`}>
            <div className="ModernDashboardMain-MasterGraphHeaderContainer">
                <h1 className="ModernDashboardMain-PhotosContainerText">Master Graph</h1>
            </div>
            <div className="ModernDashboardMain-CarouselContainer">
                <DetailedGraphComponent
                    className="MasterGraphStyles"
                    id={selectedTimeframe}
                    maximumValue={masterGraphMaximumValue}
                    showTemperature={showTemperature}
                    showHumidity={showHumidity}
                    showCO2_Density={showCO2_Density}
                    GraphsData={data}
                    timeframe={timeframe}
                />
            </div>
            <FormGroup style={{ color: 'white', display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                <FormControlLabel control={<Checkbox defaultChecked />} onChange={handleShowTemperatureClick} label="Temperature" />
                <FormControlLabel control={<Checkbox defaultChecked />} onChange={handleShowHumidityClick} label="Humidity" />
                <FormControlLabel control={<Checkbox defaultChecked />} onChange={handleShowCO2_DensityClick} label="CO2 Density" />
            </FormGroup>
        </div>
    );
};
