import React, { useEffect, useState, useRef } from 'react';
import './Speedometer.css'; // Import CSS file for styles
import ReactSpeedometer from "react-d3-speedometer";

export const ResponsiveSpeedometer = (props) => {
    const containerRef = useRef(null);
    const [size, setSize] = useState({ width: 100, height: 100 });
    const [value, setValue] = useState(null);
    const [initialAnimation, setInitialAnimation] = useState(true);
    const [fontSize, setFontSize] = useState('25px');
    const [valueFontSize, setValueFontSize] = useState('25px');
    const [nameFontSize, setNameFontSize] = useState('20px');
    const [showGraph, setShowGraph] = useState(true);

    const updateFontSizes = () => {
        if (window.innerWidth < 580) {
            setValueFontSize('12px');
            setNameFontSize('13px');
        } else if (window.innerWidth < 768) {
            setValueFontSize('18px');
            setNameFontSize('16px');
        } else if (window.innerWidth < 1000) {
            setValueFontSize('23px');
            setNameFontSize('21px');
        } else {
            setValueFontSize('25px');
            setNameFontSize('32px');
        }
    };

    const updateVisibility = () => {
        if (window.innerWidth < 930) {
            setShowGraph(false);
        } else {
            setShowGraph(true);
        }
    };

    useEffect(() => {
        // Set the initial font sizes and visibility
        updateFontSizes();
        updateVisibility();

        // Update font sizes and visibility on window resize
        window.addEventListener('resize', updateFontSizes);
        window.addEventListener('resize', updateVisibility);

        // Clean up the event listeners on component unmount
        return () => {
            window.removeEventListener('resize', updateFontSizes);
            window.removeEventListener('resize', updateVisibility);
        };
    }, []);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            if (entries[0].contentRect.width) {
                setSize({
                    width: entries[0].contentRect.width,
                    height: entries[0].contentRect.width * 0.8,
                });
            }
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        // Trigger initial animation sequence
        setTimeout(() => {
            // setValue(maxValue);
            setTimeout(() => {
                setTimeout(() => {
                    setValue(Number(props.value));
                    setInitialAnimation(false);
                }, 1000);
            }, 1000);
        }, 1000);

        return () => {
            resizeObserver.disconnect();
        };
    }, [props.value]);

    useEffect(() => {
        // Skip initial animation render
        if (!initialAnimation) {
            setValue(Number(props.value));
        }
    }, [props.value]);

    let maxValue = 25;
    let endColor = "red";
    let textColor = "rgb(255, 222, 222)";
    let fancyName = "Temperature";

    if (props.type === "temperature") {
        // default
    } else if (props.type === "humidity") {
        maxValue = 100;
        endColor = "blue";
        textColor = "rgb(222, 222, 255)";
        fancyName = "Humidity";
    } else if (props.type === "carbon_dioxide") {
        maxValue = 4000;
        endColor = "orange";
        textColor = "rgb(255, 239, 210)";
        fancyName = "CO2";
    }

    return (
        <div ref={containerRef} className="SpeedometerContainer" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {showGraph ? (
                <>
                    <ReactSpeedometer
                        // size 
                        width={size.width}
                        height={size.height}
                        paddingVertical={10}
                        // value
                        maxValue={maxValue}
                        value={value || 0}
                        maxSegmentLabels={5}
                        segments={1000}
                        valueTextFontSize={fontSize}
                        // colors
                        needleColor="white"
                        startColor="white"
                        endColor={endColor}
                        textColor={textColor}
                        // redraw
                        forceRender={true}
                    />
                    <center><h2 style={{ fontSize: nameFontSize }}>{fancyName}</h2></center>
                </>
            ) : (
                <div style={{ fontSize: valueFontSize, color: textColor }}>
                    {value} {fancyName}
                </div>
            )}
        </div>
    );
};

export default ResponsiveSpeedometer;
