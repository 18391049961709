import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import './App.css';
import ModernDashboardMain from "./Components/Main"
import {
  AuthorizerProvider,
  Authorizer,
  useAuthorizer,
} from '@authorizerdev/authorizer-react'

function App() {
  return (
    <AuthorizerProvider
      config={{
        authorizerURL: 'https://auth.mycroharvestserver.com',
        redirectURL: window.location.origin,
        clientID: 'ab1725d5-9ba4-4511-9acc-0fe911b4b976', // obtain your client id from authorizer dashboard
        extraHeaders: {}, // Optional JSON object to pass extra headers in each authorizer requests.
      }}
    >
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            {/* <Route path="/login" element={<LoginPage />} /> */}
            <Route path="/debug" element={<Debug />} />
          </Routes>
        </div>
      </Router>
    </AuthorizerProvider>
  );
}

function LoginPage() {
  return (
    <>
      <h1 style={{ textAlign: 'center' }}>Farm Management Login</h1>
      <Authorizer/>
    </>
  );
}
 
const Profile = () => {
  const { user } = useAuthorizer()
 
  if (user) {
    return <div>{user.email}</div>
  }
 
  return null
}

// Wrap the ModernDashboardMain component with the withRouter HOC
function Dashboard() {
  let { accountName } = useParams();

  const { token, user } = useAuthorizer()

  if (!token) {
    return <LoginPage />
  }

  return (
    <ModernDashboardMain accountName={user.email} token={token}/>
  );
}

function Debug() {
  let { accountName } = useParams();

  const { token, user } = useAuthorizer()

  return (
    <div>
      <h1>Token</h1>
      <pre> { JSON.stringify(token, null, 6) }</pre>
      <h1>User</h1>
      <pre> { JSON.stringify(user, null, 2) }</pre>
    </div>
  );
}

export default App;
