import React, { useState } from 'react';
import './TimelineSlider.css'; // Import the CSS file

const TimelineSlider = ({ timeframe, setTimeframe }) => { 
  const handleTimeframeChange = (value) =>{
    setTimeframe(value);
  };
  
  return (
      <div className="timeline-slider">
          <h3>Timeline:</h3>
      <button
        className={`timeframe-button ${timeframe === '1D' ? 'active' : ''}`}
        onClick={() => handleTimeframeChange('1D')}
      >
        1D
      </button>
      <button
        className={`timeframe-button ${timeframe === '5D' ? 'active' : ''}`}
        onClick={() => handleTimeframeChange('5D')}
      >
        5D
      </button>
      <button
        className={`timeframe-button ${timeframe === '1W' ? 'active' : ''}`}
        onClick={() => handleTimeframeChange('1W')}
      >
        1W
      </button>
      <button
        className={`timeframe-button ${timeframe === '1M' ? 'active' : ''}`}
        onClick={() => handleTimeframeChange('1M')}
      >
        1M
      </button>
      <button
        className={`timeframe-button ${timeframe === '3M' ? 'active' : ''}`}
        onClick={() => handleTimeframeChange('3M')}
      >
        3M
      </button>
      <button
        className={`timeframe-button ${timeframe === 'MAX' ? 'active' : ''}`}
        onClick={() => handleTimeframeChange('MAX')}
      >
        Max
      </button>
    </div>
  );
};

export default TimelineSlider;
