import { Scatter, XAxis, YAxis, Tooltip, Area, ComposedChart, Dot, ResponsiveContainer } from 'recharts';

function CustomizedXAxisTick({x, y, payload}) {
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" transform="rotate(-0)">
                {new Date(payload.value).toLocaleDateString()}
            </text>
        </g>
    );
}

function CustomizedYAxisTick({x, y, payload}) {
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={4} dx={-20} textAnchor="middle" fill="#666" transform="rotate(-0)">
                {Math.round(payload.value)}
            </text>
        </g>
    );
}

const CustomizedShape = (props) => {
    const {cx, cy, fill} = props;
    return (
        <Dot cx={cx} cy={cy} fill={fill} r={2}/>
    );
};

const CustomTooltip = ({ active, payload, label }) => {
    // data key pretty names
    if (payload && payload.length > 0) {
        if (payload[0].dataKey == "temperature") {
            payload[0].dataKey = "Temperature";
        }
        else if (payload[0].dataKey == "humidity") {
            payload[0].dataKey = "Humidity";
        }
        else if (payload[0].dataKey == "carbon_dioxide") {
            payload[0].dataKey = "CO2 Density";
        }
        else if (payload[0].dataKey == "nmushrooms") {
            payload[0].dataKey = "# of Mushrooms";
        }
    }
    else {
        return null;
    }

    if (active) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                <p className="label">{`Timestamp: ${new Date(label).toLocaleDateString()}`}</p>
                <p className="label">{`${payload[0].dataKey}: ${payload[0].value}`}</p>
            </div>
        );
    }
    return null;
};

const SummaryChart = (props) => {
    // appearance configuration
    if (props.type == "temperature") {
        var stroke = "red";
        var fill = "red";
        var unit = "°C";
        var key = "temperature";
        var width = 330;
        var height = 200;
    }
    else if (props.type == "humidity") {
        var stroke = "blue";
        var fill = "blue";
        var unit = "%";
        var key = "humidity";
        var width = 330;
        var height = 200;
    }
    else if (props.type == "carbon_dioxide") {
        var stroke = "orange";
        var fill = "orange";
        var unit = "ppm";
        var key = "carbon_dioxide";
        var width = 330;
        var height = 200;
    }
    else if (props.type == "nmushrooms") {
        var stroke = "#8884d8"
        var fill = "#8884d8"
        var unit = "mushrooms"
        var key = "nmushrooms"
        var width = 1100
        var height = 240
    }

    return (
        // Graph container
        <div class="GraphContainer">
            {/* Actual graph */}
            <div class="ModernDashboardMain-Graph" style={{ width: '100%', height: {height} }}>
                <ResponsiveContainer width={'100%'} height={height}>
                    <ComposedChart data={props.data} margin={{ top: 5, right: 20, bottom: 15, left: 0 }}>
                        <XAxis dataKey="x" type="number" domain={['dataMin', 'dataMax']} scale="time" tick={<CustomizedXAxisTick />}/>
                        <YAxis dataKey={key} type="number" domain={['dataMin', 'dataMax']} tick={<CustomizedYAxisTick />}/>
                        <Tooltip content={<CustomTooltip />} />
                        <Area type="monotone" dataKey={key} stroke={stroke} fillOpacity={0.3} fill={fill}/>
                        <Scatter dataKey={key} fill={fill} shape={<CustomizedShape />} />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

export default SummaryChart