// Layout
import Grid from '@mui/material/Unstable_Grid2';

// Styling
import "./Analytics.css";

// Components
import SummaryChart from '../Graphs/SummaryChart';
import { ResponsiveSpeedometer } from '../Graphs/Speedometer';
import { InferredStat } from '../Graphs/Inferred';
import { Alert } from '../Graphs/Alert';
import React, { useEffect, useState } from 'react';

// Import Data Processing Functions
import { processInferredData } from '../../Data/GraphProcess';

export const Analytics = ({ GraphsData, InferredData, AlertState, AvgCapDiameter, NumberofMushrooms, ExpectedYield, selectedSection, selectedTimeframe, timeframe }) => {
    const [filteredData, setFilteredData] = useState([]);
    const [nowTemp, setNowTemp] = useState(undefined);
    const [nowHum, setNowHum] = useState(undefined);
    const [nowCO2, setNowCO2] = useState(undefined);

    function Checkinbetween(Data, adj) {
        if (!Data || !Array.isArray(Data)) {
            return [];
        }

        const currentTimeMilliseconds = Date.now();
        const adjustedDate = currentTimeMilliseconds - adj;
        return Data.filter(item => adjustedDate < item["x"] && item["x"] < currentTimeMilliseconds);
    }

    useEffect(() => {
        let filtered = [];

        if (GraphsData !== null) {
            switch (timeframe) {
                case '1D':
                    filtered = Checkinbetween(GraphsData.data, 86400000);
                    break;
                case '5D':
                    filtered = Checkinbetween(GraphsData.data, 5 * 86400000);
                    break;
                case '1W':
                    filtered = Checkinbetween(GraphsData.data, 7 * 86400000);
                    break;
                case '1M':
                    filtered = Checkinbetween(GraphsData.data, 30 * 86400000);
                    break;
                case '3M':
                    filtered = Checkinbetween(GraphsData.data, 90 * 86400000);
                    break;
                case 'MAX':
                    filtered = GraphsData.data;
                    break;
                default:
                    filtered = GraphsData.data;
            }

            setFilteredData(filtered);
            setNowTemp(GraphsData.latestTemp);
            setNowHum(GraphsData.latestHum);
            setNowCO2(GraphsData.latestCO2);
        } else {
            setFilteredData([]);
            setNowTemp(undefined);
            setNowHum(undefined);
            setNowCO2(undefined);
        }
    }, [timeframe, GraphsData]);

    let ProcessedInferredData = {};

    if (InferredData !== null) {
        ProcessedInferredData = processInferredData(InferredData);
    } else {
        ProcessedInferredData = {
            x: 0,
            avgcapdiameter: 0,
            nmushrooms: 0,
            expectedyield: 0,
        };
    }

    return (
        <div className={`ModernDashbordMain-AnalyticsContainer ${!(selectedSection === 0) && "hidden"}`}>
            <Grid container spacing={2}>
                {/* Warning container */}
                <Grid container spacing={6} alignItems="flex-start" style={{ width: '100%' }}>
                    <Grid lg={12} xs={12}>
                        <Alert
                            data={AlertState}
                        />
                    </Grid>
                </Grid>
                {/* Summary container */}
                <Grid container spacing={6} alignItems="flex-start" style={{ width: '100%' }}>
                    {/* Speedometers */}
                    <Grid lg={3} xs={4} minWidth={150} wrap="false">
                        <ResponsiveSpeedometer
                            value={nowTemp}
                            type="temperature"
                            fluidWidth={true}
                            forceRender={true}
                        />
                    </Grid>
                    <Grid lg={3} xs={4} minWidth={150} wrap="false">
                        <ResponsiveSpeedometer
                            value={nowHum}
                            type="humidity"
                            fluidWidth={true}
                            forceRender={true}
                        />
                    </Grid>
                    <Grid lg={3} xs={4} minWidth={150} wrap="false">
                        <ResponsiveSpeedometer
                            value={nowCO2}
                            type="carbon_dioxide"
                            fluidWidth={true}
                            forceRender={true}
                        />
                    </Grid>
                    {/* Metrics */}
                    <Grid lg={3} xs={12}>
                        <Grid className="Metric" container sx={{ flexDirection: {xs: 'row', lg: 'column' } }} spacing={0}>
                            <Grid lg={4} md={12} class="AnalyticsMetric">
                                <InferredStat value={AvgCapDiameter} text="Average Cap Diameter" icon="avgcapdiameter"/>
                            </Grid>
                            <Grid lg={4} md={12} class="AnalyticsMetric">
                                <InferredStat value={NumberofMushrooms} text="# of Mushrooms" icon="nmushrooms"/>
                            </Grid>
                            <Grid lg={4} md={12} class="AnalyticsMetric">
                                <InferredStat value={ExpectedYield} text="Expected Yield" icon="expectedyield"/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Container for graphs */}
                <Grid container spacing={6} alignItems="flex-start" style={{ width: '100%' }}>
                    <Grid lg={3} xs={12} minWidth={300}>
                        <SummaryChart data={filteredData} type="temperature" />
                    </Grid>
                    <Grid lg={3} xs={12} minWidth={300}>
                        <SummaryChart data={filteredData} type="humidity" />
                    </Grid>
                    <Grid lg={3} xs={12} minWidth={300}>
                        <SummaryChart data={filteredData} type="carbon_dioxide" />
                    </Grid>
                    <Grid lg={3} xs={12}>
                        <h1></h1>
                    </Grid>
                </Grid>
                {/* Big boi graph */}
                <Grid container spacing={6} alignItems="flex-start" style={{ width: '100%' }}>
                    <Grid lg={9} xs={12} minWidth={300}>
                        <SummaryChart data={ProcessedInferredData} type="nmushrooms" />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
