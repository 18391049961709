// Handles fetching of inferred data from MycroHarvest API
// Inferred data includes average cap diameter, number of mushrooms, and expected yield

import axios from 'axios';

export async function fetchData(token, baseURL, AccountID) {
    // input: baseURL (dev or prod)
    // output: JSON object containing inferred data
    try {
        var response = await axios.get(baseURL + 'account/' + AccountID + '/inference', {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error("Failed to fetch Inferred Data from MycroHarvest API. Error: ", error);
        throw error;
    }
}
