import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import TimelineSlider from '../TimelineSlider';
import CustomTooltip from './Tooltips.js';
import "./DetailedGraph.css";

function CustomizedXAxisTick({ x, y, payload }) {
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" transform="rotate(-0)">
                {new Date(payload.value).toLocaleDateString()}
            </text>
        </g>
    );
}

function Checkinbetween(Data, adj) {
    const currentTimeMilliseconds = Date.now();
    let adjustedDate = currentTimeMilliseconds - adj;
    let temp = [];

    for (let i = 0; i < Data.length; i++) {
        if (adjustedDate < Data[i]["x"] && Data[i]["x"] < currentTimeMilliseconds) {
            temp.push(Data[i]);
        }
    }
    return temp;
}

export const DetailedGraph = ({ GraphsData, timeframe, showTemperature, showHumidity, showCO2_Density }) => {
    const [filteredData, setFilteredData] = useState(GraphsData);

    useEffect(() => {
        let filtered;

        switch (timeframe) {
            case '1D':
                filtered = Checkinbetween(GraphsData, 86400000);
                break;
            case '5D':
                filtered = Checkinbetween(GraphsData, 5 * 86400000);
                break;
            case '1W':
                filtered = Checkinbetween(GraphsData, 7 * 86400000);
                break;
            case '1M':
                filtered = Checkinbetween(GraphsData, 30 * 86400000);
                break;
            case '3M':
                filtered = Checkinbetween(GraphsData, 90 * 86400000);
                break;
            case 'MAX':
                filtered = GraphsData;
                break;
            default:
                filtered = GraphsData;
        }

        setFilteredData(filtered);
    }, [timeframe, GraphsData]);

    return (
        <div className="recharts-wrapper">
            <LineChart width={1000} height={400} data={filteredData} margin={{ top: 5, right: 0, bottom: 15, left: 0 }}>
                <XAxis dataKey="x" type="number" domain={['auto', 'auto']} scale="time" tick={<CustomizedXAxisTick />} />
                <YAxis domain={['dataMin', 'dataMax']} />
                <Tooltip content={<CustomTooltip />} />
                {showTemperature && <Line type="monotone" dataKey="temperature" stroke="red" dot={false} />}
                {showHumidity && <Line type="monotone" dataKey="humidity" stroke="blue" dot={false} />}
                {showCO2_Density && <Line type="monotone" dataKey="carbon_dioxide" stroke="orange" dot={false} />}
            </LineChart>
        </div>
    );
};
