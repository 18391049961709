import { convertToTimestamp, convertToTimestampNew } from '../Functions/Timestamp';

export function processRawData(temperatureData, humidityData, carbonDioxideData) {
    // we need x, y lists for each capability
    // iterate through each capability and create a list of x, y values
    // x values are timestamps, y values are the numerical value
    const temperature = [];
    const humidity = [];
    const carbon_dioxide = [];
    var latestTemp = undefined;
    var latestHum = undefined;
    var latestCO2 = undefined;

    Object.values(temperatureData).forEach(item => {
        const timestamp = convertToTimestampNew(item.timestamp);
        const value = item.value;

        temperature.push({ x: timestamp, temperature: value });

        // sort the data by timestamp
        temperature.sort((a, b) => a.x - b.x);
        // humidity.sort((a, b) => a.x - b.x);
        // carbon_dioxide.sort((a, b) => a.x - b.x);
    });

    Object.values(humidityData).forEach(item => {
        const timestamp = convertToTimestampNew(item.timestamp);
        const value = item.value;

        humidity.push({ x: timestamp, humidity: value });

        // sort the data by timestamp
        humidity.sort((a, b) => a.x - b.x);
    });

    Object.values(carbonDioxideData).forEach(item => {
        const timestamp = convertToTimestampNew(item.timestamp);
        const value = item.value;

        carbon_dioxide.push({ x: timestamp, carbon_dioxide: value });

        // sort the data by timestamp
        // carbon_dioxide.sort((a, b) => a.x - b.x);
    });

    // align data into { x: 1, temperature: 20, co2: 400, humidity: 70 }, ...
    var data = [];
    for (let i = 0; i < temperature.length; i++) {
        data.push({ x: temperature[i]['x'], temperature: temperature[i]['temperature'] });
        latestTemp = temperature[i]['temperature'];
        // round to one decimal place for latestTemp
        latestTemp = Math.round(latestTemp * 10) / 10;
    }
    // iterate over humidity, if the same timestamp exists, add humidity to the object. otherwise, add a new object
    for (let i = 0; i < humidity.length; i++) {
        const timestamp = humidity[i]['x'];
        const thishumidity = humidity[i]['humidity'];
        const existing = data.find(element => element.x === timestamp);
        if (existing) {
            existing.humidity = thishumidity;
        } else {
            data.push({ x: timestamp, humidity: thishumidity });
        }
        latestHum = humidity[i]['humidity'];
        latestHum = Math.round(latestHum * 10) / 10;
    }
    // iterate over carbon_dioxide, if the same timestamp exists, add carbon_dioxide to the object. otherwise, add a new object
    for (let i = 0; i < carbon_dioxide.length; i++) {
        const timestamp = carbon_dioxide[i]['x'];
        const thiscarbon_dioxide = carbon_dioxide[i]['carbon_dioxide'];
        const existing = data.find(element => element.x === timestamp);
        if (existing) {
            existing.carbon_dioxide = thiscarbon_dioxide;
        } else {
            data.push({ x: timestamp, carbon_dioxide: thiscarbon_dioxide });
        }
        latestCO2 = carbon_dioxide[i]['carbon_dioxide'];
        latestCO2 = Math.round(latestCO2 * 10) / 10;
    }
    // sort data by timestamp
    data.sort((a, b) => a.x - b.x);

    return { data, latestTemp, latestHum, latestCO2 };
}

export function processInferredData(inferredData) {
    var data = [];
    Object.values(inferredData).forEach(item => {
        data.push({ x: convertToTimestamp(item.timestamp), avgcapdiameter: item.avgcapdiameter, nmushrooms: item.nmushrooms, expectedyield: item.expectedyield });
    });
    // sort data by timestamp
    data.sort((a, b) => a.x - b.x);
    
    return data;
}