// Handles fetching of account data from MycroHarvest API

import axios from 'axios';

export async function fetchData(token, baseURL, AccountName) {
    // input: baseURL (dev or prod)
    // output: JSON object containing raw data
    try {
        const response = await axios.get(baseURL + 'account/' + AccountName, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch Account Data from MycroHarvest API. Error: ", error);
        throw error;
    }
}