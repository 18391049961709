// Handles fetching of raw data from MycroHarvest API
// Raw data includes temperature, humidity, and CO2 levels

import axios from 'axios';

export async function fetchData(token, baseURL, AccountID) {
    // input: baseURL (dev or prod)
    // output: JSON object containing raw data
    try {
        var temperature = axios.get(baseURL + 'account/' + AccountID + '/rawdata?capability=temperature&sensorid=50c2cd41-f168-4915-b72a-c1cb41dbbea7', {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        var humidity = axios.get(baseURL + 'account/' + AccountID + '/rawdata?capability=humidity&sensorid=c9b5afdd-4880-43d4-bd7d-b9636237cddb', {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        var carbon_dioxide = axios.get(baseURL + 'account/' + AccountID + '/rawdata?capability=carbon_dioxide&sensorid=35a722cd-6b63-483a-b32b-f91d1888501c', {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        // wait here for all promises to resolve
        temperature = await temperature;
        humidity = await humidity;
        carbon_dioxide = await carbon_dioxide;
        return [temperature.data, humidity.data, carbon_dioxide.data];
    } catch (error) {
        console.error("Failed to fetch Raw Data from MycroHarvest API. Error: ", error);
        throw error;
    }
}