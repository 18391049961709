import React from 'react';
import { DeviceTable } from '../Tables/Table';
import "./ControlPanel.css"

export const ControlPanel = ({ DeviceState, selectedSection, PerformCapture }) => {
    
    const columns = React.useMemo(
        () => [
          { Header: 'Device ID', accessor: 'name' }, // Define how each column is rendered
          { Header: 'Capture Status', accessor: 'status' },
        ],
        [] // Dependencies array, empty means it only computes once
      );

    return(
        <div class={`ModernDashbordMain-MasterGraphContainer ${!(selectedSection === 3) && "hidden"}`}>
            <div class="ModernDashbordMain-MasterGraphHeaderContainer">
                <h1 class="ModernDashbordMain-PhotosContainerText">Device Management</h1>
            </div>
            {/* insert table */}

            { DeviceState ? (
                <DeviceTable columns={columns} data={DeviceState} />
            ) : (
                <h1 className="TextName">Loading...</h1>
            )}
            <button className="ControlPanelButton" onClick={PerformCapture}>Capture Image/Sensor Readings</button>
        </div>
    );
};