// Icon for sidebar open close
import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ViewListIcon from '@mui/icons-material/ViewList';
import TimelineChart from './TimelineSlider';
import './Header.css'


/* Unused imports.*/
/*
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
*/

export const Header = ({ HumanUser, dayName, hours, minutes, seconds, selectedTimeframe, handleSelectedTimeframeChange, toggleDrawer, isOpen, timeframe, setTimeframe }) => (
    <div class="ModernDashboardMain-AnalyticsHeaderContainer">
        { HumanUser ? (
            <div>
                <h1 class="ModernDashboardMain-AnalyticsHeader">Welcome Back, <span class="highlighted-user">{HumanUser.split(" ")[0]}!</span>
                </h1> 
                {!isOpen && (
                    <IconButton sx={{ color: "white" }} onClick={toggleDrawer}>
                        <ViewListIcon />
                        <ArrowRightIcon />
                    </IconButton>
                )}
                <h1 class="ModernDashboardMain-AnalyticsSubHeader">Here are your updates for {dayName},</h1> {/*Removed {hours}:{minutes}:{seconds}*/}
            </div>
        ) : (
            <h1 class="ModernDashboardMain-AnalyticsHeader">Loading...</h1>
        )}

        {/* This implements Timeline Slider(Button).*/}
        <div>
            <TimelineChart timeframe={timeframe} setTimeframe={setTimeframe} />
        </div>
        
        
        {/* This removes the previous Form style and changes it to Button Style. */}
        {/* <div class="ModernDashboardMain-AnalyticsDropdownContainer">   
        <FormControl sx={{ m: 1, minWidth: 120, color: 'white' }} size="small" fullWidth>
            <InputLabel id="demo-simple-select-filled-label" sx={{ color: 'rgb(26, 26, 26)'}}>Timescale</InputLabel>
            <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            value={selectedTimeframe}
            onChange={handleSelectedTimeframeChange}
            sx={{ color: 'black', backgroundColor: 'white' }}
            >
            <MenuItem value={3}>From the Start</MenuItem>
            <MenuItem value={2}>100 datapoints</MenuItem>
            <MenuItem value={1}>30 datapoints</MenuItem>
            <MenuItem value={0}>7 datapoints</MenuItem>
            </Select>
            </FormControl>
        </div> */}

    </div>
);