import {React, useState, useEffect} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

export const MobileCarousel = (props) => {
  const slides = [
    { id: 1, text: Object.values(props.imageKeys)[0].timestamp, imageUrl: Object.values(props.imageKeys)[0].rawurl, link: '/solutions/spam-detection'},
    { id: 2, text: Object.values(props.imageKeys)[1].timestamp, imageUrl: Object.values(props.imageKeys)[1].rawurl, link: '/datasets/diabetes-data' },
    { id: 3, text: Object.values(props.imageKeys)[2].timestamp, imageUrl: Object.values(props.imageKeys)[2].rawurl, link: '/datasets/airline-data' },
    { id: 4, text: Object.values(props.imageKeys)[3].timestamp, imageUrl: Object.values(props.imageKeys)[3].rawurl, link: '/solutions/object-segmentation' },
  ];
  
  
  return (
    <Swiper
      modules={[Autoplay, Pagination, Navigation]}
      spaceBetween={0} 
      slidesPerView="3"
      navigation
      loop={true}
      autoplay={{ delay: 4000 }}
      pagination={{ clickable: true }}
    >
      {slides.map((slide) => (
        <SwiperSlide key={slide.id} style={{ width: '250px'}}>
            <div className="MobileCarousel-SlideContainer">
              <img src={slide.imageUrl} alt={slide.text} style={{ height: 'auto', width: '25vw', borderRadius: "0.5rem" }}/>
              <p class="MobileCarousel-SlideText" style={{ color: "white", height:'10vh', paddingTop:'10px'}}>{slide.text}</p>
            </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};