// Handles fetching of image data from MycroHarvest API

import axios from 'axios';

export async function fetchData(token, baseURL, AccountID) {
    // input: baseURL (dev or prod)
    // output: JSON object containing raw data
    try {
        const response = await axios.get(baseURL + 'account/' + AccountID + '/rawdata?capability=camera&mostrecent=4', {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch Images from MycroHarvest API. Error: ", error);
        throw error;
    }
}