import React from 'react';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const formattedDate = new Date(label).toLocaleDateString();

        return (
            <div className="custom-tooltip" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', border: '1px solid #ccc', padding: '10px' }}>
                <p className="label" style={{ color: '#000' }}>{formattedDate}</p>
                {payload.map((entry, index) => (
                    <p key={index} style={{ color: entry.color }}>{`${entry.name}: ${entry.value}`}</p>
                ))}
            </div>
        );
    }

    return null;
};

export default CustomTooltip;
