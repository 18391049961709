// Layout
import Grid from '@mui/material/Unstable_Grid2';

// Components
import { TbRulerMeasure } from "react-icons/tb";
import { GiMushroomsCluster } from "react-icons/gi";
import { GiSlicedMushroom } from "react-icons/gi";

export const InferredStat = (props) => {
    var metricValue = props.value;
    var metricText = props.text;
    var metricIcon = props.icon;

    if (metricIcon === "nmushrooms") {
        metricIcon = <GiMushroomsCluster class="AnalyticsIcon"/>
    }
    else if (metricIcon === "avgcapdiameter") {
        metricIcon = <TbRulerMeasure class="AnalyticsIcon"/>
    }
    else if (metricIcon === "expectedyield") {
        metricIcon = <GiSlicedMushroom class="AnalyticsIcon"/>
    }

    return (
        <Grid container wrap="false" padding={1} spacing={1} alignItems="center">
            <Grid xs={3} minWidth={40}>
                {metricIcon}
            </Grid>
            <Grid xs={9}>
                <h1 class="AnalyticsMetricText">{metricValue}</h1>
                <h1 class="AnalyticsMetricSubText">{metricText}</h1>
            </Grid>
        </Grid>
    )
}