// Layout
import Grid from '@mui/material/Unstable_Grid2';

// Components
import { AiFillWarning } from "react-icons/ai";

export const Alert = (props) => {

    // check if props data is passed and any alerts are present
    if (props.data !== null) {
        for (let i = 0; i < props.data.length; i++) {
            if (props.data[i].status === "❌") {
                return (
                    <div class="AlertMetric">
                        <Grid xs={3} maxWidth={90} wrap="nowrap">
                            <AiFillWarning class="AlertIcon"/>
                        </Grid>
                        <Grid xs={9} wrap="nowrap"> 
                            <h1>Alert!</h1>
                            <h3>Value exceeding threshold. Please see Alerts page for detailed information.</h3>
                        </Grid>
                    </div>
                )
            }
        }
    }
}