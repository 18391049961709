// Handles fetching and sending of device data from MycroHarvest API

import axios from 'axios';

export async function fetchData(token, baseURL, AccountID) {
    // input: baseURL (dev or prod)
    // output: JSON object containing raw data
    try {
        const response = await axios.get(baseURL + 'account/' + AccountID + '/device', {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch Device Data from MycroHarvest API. Error: ", error);
        throw error;
    }
}

export async function captureData(token, baseURL, AccountID, CaptureStateChange, setCaptureStateChange) {
    // input: baseURL (dev or prod)
    // output: JSON object containing status of request
    try {
        const response = await axios.get(baseURL + 'perform_capture/' + AccountID, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        setCaptureStateChange(!CaptureStateChange);
        return response.data;
    } catch (error) {
        console.error("Failed to communicate capture request to MycroHarvest API. Error: ", error);
        throw error;
    }
}