import React, {useState, useEffect} from 'react';
import "./Main.css"

// Layout
import { Drawer, Box } from '@mui/material';

// Import components of interface
import { Sidebar } from './Sidebar';
import { Header } from './Header';

// Import pages
import { Analytics } from './Pages/Analytics';
import { MasterGraph } from './Pages/MasterGraph';
import { ControlPanel } from './Pages/ControlPanel';
import { MobileCarousel } from './Pages/Photos';
import { Alerts } from './Pages/Alerts';

// Import Data Loading Functions
import { fetchData as fetchRawData } from '../Data/RawData';
import { fetchData as fetchImageData } from '../Data/ImageData';
import { fetchData as fetchAccountData } from '../Data/AccountData';
import { fetchData as fetchAlertData } from '../Data/AlertData';
import { fetchData as fetchDeviceData, captureData } from '../Data/DeviceData';
import { fetchData as fetchInferredData } from '../Data/InferredData';

// Import Data Processing Functions
import { processRawData } from '../Data/GraphProcess';
import { processDeviceData } from '../Data/TableProcess';
import { processAlertData } from '../Data/TableProcess';

var sidebarWidth = 260;

const ModernDashboardMain = (props) => {
    // if props is empty, use a dummy value
    // if (props.accountName === undefined) {
    //     const { accountName }  = "allison-dev"
    // }
    const accountName = props.accountName;
    const token = props.token.id_token;

    // get API URL based on app state
    var networkAddress = 'https://backend.mycroharvestserver.com/v2/';
    // var networkAddress = 'http://127.0.0.1:5000/';
    // var networkAddress = 'https://mycroharvest-desktop.tiffany-marlin.ts.net/dev/api/allison/'

    // S3 URL config
    const BaseURL = "https://mycroharvest-accountimages.s3.amazonaws.com/";

    // Account configuration, defines the user's name, ID of records we're pulling, etc.
    const [AccountID, setAccountID] = useState(null)
    const [AccountImage, setAccountImage] = useState(null)
    const [HumanUser, setHumanUser] = useState(null)
    const [CompanyName, setCompanyName] = useState(null)

    // Alert info
    const [AlertState, setAlertState] = useState(null)

    // Device info
    const [DeviceState, setDeviceState] = useState(null)
    const [CaptureStateChange, setCaptureStateChange] = useState(false)

    // State for sidebar width and open/close
    const [isOpen, setIsOpen] = useState(true);
    const [sidebarWidth, setSidebarWidth] = useState(260); // Default width

    const [selectedTimeframe, setSelectedTimeframe] = useState(null);
    const [timeframe, setTimeframe] = useState('1D');
    const [selectedSection, setSelectedSection] = useState(0);
    const [imageKeys, setImageKeys] = useState(null)

    const [GraphsData, setGraphsKeys] = useState(null)
    const [InferredData, setInferredData] = useState(null)
    const [AvgCapDiameter, setAvgCapDiameter] = useState(null)
    const [ExpectedYield, setExpectedYield] = useState(null)
    const [NumberofMushrooms, setNumberofMushrooms] = useState(null)

    var currentDate = new Date();
    var dayOfWeek = currentDate.getDay();
    var hours = currentDate.getHours();
    var minutes = currentDate.getMinutes();
    var seconds = currentDate.getSeconds();
    // Define an array of days to map the numbers to day names
    var daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    // Get the day name
    var dayName = daysOfWeek[dayOfWeek];

    // Fetch account data on component mount
    useEffect(() => {
        fetchAccountData(token, networkAddress, accountName).then(response => {
            setAccountID(response.accountid)
            setHumanUser(response.humaninfo)
            setCompanyName(response.companyinfo)
            setAccountImage(`${BaseURL}${response.accountid}.png`)
        });
    }, [networkAddress, accountName]);

    // Fetch data when AccountID changes
    useEffect(() => {
        if (AccountID) {
            fetchRawData(token, networkAddress, AccountID).then(response => {
                response = processRawData(response[0], response[1], response[2]);
                setGraphsKeys(response);
            });
            fetchImageData(token, networkAddress, AccountID).then(response => {
                setImageKeys(response);
            });
            fetchDeviceData(token, networkAddress, AccountID).then(response => {
                response = processDeviceData(response);
                setDeviceState(response);
            });
            fetchAlertData(token, networkAddress, AccountID).then(response => {
                response = processAlertData(response);
                setAlertState(response);
            });
            fetchInferredData(token, networkAddress, AccountID).then(response => {
                setInferredData(response);
                setAvgCapDiameter(response[Object.keys(response)[0]].capdiameter);
                setExpectedYield(response[Object.keys(response)[0]].expectedyield);
                setNumberofMushrooms(response[Object.keys(response)[0]].nmushrooms);
            });
        }
    }, [networkAddress, AccountID, CaptureStateChange]);

    // Sidebar toggle function
    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };

    // Handle window resize to adjust sidebar width
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 930) {
                setSidebarWidth(0);
                setIsOpen(false);
            } else {
                setSidebarWidth(260);
                setIsOpen(true);
            }
        };

        // Initial call
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Clean up
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSelectedTimeframeChange = (event) => {
        setSelectedTimeframe(event.target.value);
    };

    const handleSectionClick = (id) => {
        setSelectedSection(id);
    };

    const handleTimeframeChange = (value) => {
        setTimeframe(value);
    };

    const PerformCapture = () => {
        captureData(token, networkAddress, AccountID, CaptureStateChange, setCaptureStateChange).then(response => {
            console.log(response);
        });
    };

    return(
        <div class="ModernDashboardMain-Container">
            <Box
                sx={{ width: { sm: sidebarWidth } }}
            >
                <Drawer variant='persistent' sx={{
                    '.MuiDrawer-paper': {
                        width: sidebarWidth,
                        background: 'rgb(17, 17, 17)'
                    }
                    }}
                    open={isOpen}
                >
                    <Sidebar 
                        AccountImage={AccountImage} 
                        HumanUser={HumanUser} 
                        CompanyName={CompanyName} 
                        handleSectionClick={handleSectionClick} 
                        selectedSection={selectedSection}
                        toggleDrawer={toggleDrawer}
                    />
                </Drawer>
            </Box>
            <Box
                sx={{ flexGrow: 0, p: 2, width: { sm: `calc(100% - ${sidebarWidth}px)` } }}
            >    
                <Header
                    HumanUser={HumanUser}
                    dayName={dayName}
                    hours={hours}
                    minutes={minutes}
                    seconds={seconds}
                    selectedTimeframe={selectedTimeframe}
                    handleSelectedTimeframeChange={handleSelectedTimeframeChange}
                    toggleDrawer={toggleDrawer}
                    isOpen={isOpen}
                    timeframe={timeframe}
                    setTimeframe={setTimeframe}
                />
                <Analytics
                    GraphsData={GraphsData}
                    InferredData={InferredData}
                    AlertState={AlertState}
                    AvgCapDiameter={AvgCapDiameter}
                    ExpectedYield={ExpectedYield}
                    NumberofMushrooms={NumberofMushrooms}
                    selectedSection={selectedSection}
                    selectedTimeframe={selectedTimeframe}
                    timeframe={timeframe}
                />
                <div class={`ModernDashbordMain-PhotosContainer ${!(selectedSection === 1) && "hidden"}`}>
                    <h1 class="ModernDashbordMain-PhotosContainerText">Recent Photos</h1>
                    <div class="ModernDashbordMain-CarouselContainer">
                        {imageKeys && <MobileCarousel imageKeys={imageKeys}/>}
                    </div>
                </div>
                <MasterGraph
                    GraphsData={GraphsData}
                    selectedSection={selectedSection}
                    timeframe={timeframe}
                />
                <ControlPanel
                    DeviceState={DeviceState}
                    selectedSection={selectedSection}
                    PerformCapture={PerformCapture}
                />
                <Alerts
                    token={token}
                    AlertState={AlertState}
                    selectedSection={selectedSection}
                    baseURL={networkAddress}
                    AccountID={AccountID}
                />
            </Box>
        </div>
    )
}

export default ModernDashboardMain
